@charset "UTF-8";
:root {
  --blue: #377dff;
  --indigo: #2d1582;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #de4437;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #00c9a7;
  --cyan: #00dffc;
  --white: #fff;
  --gray: #8c98a4;
  --gray-dark: #343a40;
  --primary: #377dff;
  --secondary: #77838f;
  --success: #00c9a7;
  --info: #00dffc;
  --warning: #ffc107;
  --danger: #de4437;
  --light: #f8f9fa;
  --dark: #1e2022;
  --white: #fff;
  --indigo: #2d1582;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Poppins", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-family: var('--font-family-sans-serif');
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1e2022;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #377dff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0052ea;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.5;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ant-carousel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .ant-carousel .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  .ant-carousel .slick-list:focus {
    outline: none;
  }
  .ant-carousel .slick-list.dragging {
    cursor: pointer;
  }
  .ant-carousel .slick-list .slick-slide {
    pointer-events: none;
  }
  .ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto;
  }
  .ant-carousel .slick-slider .slick-track,
  .ant-carousel .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .ant-carousel .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-bottom: 2rem;
  }
  .ant-carousel .slick-track:before,
  .ant-carousel .slick-track:after {
    content: '';
    display: table;
  }
  .ant-carousel .slick-track:after {
    clear: both;
  }
  .slick-loading .ant-carousel .slick-track {
    visibility: hidden;
  }
  .ant-carousel .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  [dir='rtl'] .ant-carousel .slick-slide {
    float: right;
  }
  .ant-carousel .slick-slide img {
    display: block;
  }
  .ant-carousel .slick-slide.slick-loading img {
    display: none;
  }
  .ant-carousel .slick-slide.dragging img {
    pointer-events: none;
  }
  .ant-carousel .slick-initialized .slick-slide {
    display: block;
  }
  .ant-carousel .slick-loading .slick-slide {
    visibility: hidden;
  }
  .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .ant-carousel .slick-arrow.slick-hidden {
    display: none;
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: 0;
    outline: none;
  }
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  .ant-carousel .slick-prev:hover:before,
  .ant-carousel .slick-next:hover:before,
  .ant-carousel .slick-prev:focus:before,
  .ant-carousel .slick-next:focus:before {
    opacity: 1;
  }
  .ant-carousel .slick-prev.slick-disabled:before,
  .ant-carousel .slick-next.slick-disabled:before {
    opacity: 0.1;
  }
  .ant-carousel .slick-prev {
    left: -4rem;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  .ant-carousel .slick-prev:before {
    content: '\2190';
  }
  .ant-carousel .slick-next {
    right: -3rem;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
  .ant-carousel .slick-next:before {
    content: '\2192';
  }
  .ant-carousel .slick-prev:before, .ant-carousel .slick-next:before {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;  
    opacity: 0.5;
  }
  .ant-carousel .slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 3px;
  }
  .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0 2px;
    padding: 0;
  }
  .ant-carousel .slick-dots li button {
    border: 0;
    cursor: pointer;
    background: #fff;
    opacity: 0.3;
    display: block;
    width: 16px;
    height: 3px;
    border-radius: 1px;
    outline: none;
    font-size: 0;
    color: transparent;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    padding: 0;
  }
  .ant-carousel .slick-dots li button:hover,
  .ant-carousel .slick-dots li button:focus {
    opacity: 0.75;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
    width: 24px;
  }
  .ant-carousel .slick-dots li.slick-active button:hover,
  .ant-carousel .slick-dots li.slick-active button:focus {
    opacity: 1;
  }
  .ant-carousel-vertical .slick-dots {
    width: 3px;
    bottom: auto;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: auto;
  }
  .ant-carousel-vertical .slick-dots li {
    margin: 0 2px;
    vertical-align: baseline;
  }
  .ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px;
  }
  .ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px;
  }
